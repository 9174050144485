.header {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  max-width: 700px;
  border-bottom: 0.5px solid rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  height: 50px;
}

.header-item {
  justify-content: center;
  width: 25%;
}

.header-icon {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.navigation_item-label {
  position: absolute;
    top: 65%;
    display: block;
    text-align: center;
    font-size: 12px;
    color: #DADADA;
    font-weight: bold;
}

.current-color {
  color: #222222;
}