.works-page-wrap {
  padding: 0 16px 24px;
}

a {
  text-decoration: none;
}

.work-wrap {
  padding-top: 24px;
}

.work-image {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  max-width: 100%;
}

.loading-wrap {
  padding: 24px 16px;
  text-align: center;
}

.work-title {
  color: #696969;
  font-size: 16px;
  font-weight: bold;
  margin: 16px 0 0;
}

.work-role {
  color: #9C9892;
  font-size: 14px;
  margin: 4px 0 0;
}