.about-page-wrap {
  padding: 24px 0;
}

.profile-wrap {
  max-width: 700px;
  background-color: #fff;
  border-radius: 13px;
  margin: 0 auto;
  padding: 0 16px;
}

.profile-header {
  display: flex;
}

.profile-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.basic-text-profile-wrap {
  padding-left: 24px;
  margin: auto 0;
}

.profile-name {
  color: #222222;
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  margin-right: 20px;
}

.profile-occupation {
  color: #9C9892;
  font-weight: bold;
  font-size: 16px;
  margin-right: 20px;
}

.profile-title {
  color: #222222;
  font-weight: bold;
  font-size: 24px;
  padding-top: 32px;
}

.introduction-wrap {
  color: #696969;
  margin-top: 16px;
  max-width: 700px;
  background-color: #F6F5F3;
  border-radius: 10px;
  padding: 16px;
}

.tags-wrap {
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
}

.emphasis-tags {
  color: #FFFFFF;
  border-radius: 52px;
  background: #222222;
  margin-right: 10px;
  max-width: 280px;
  margin-bottom: 10px;
}

.normal-tags {
  color: #696969;
  border-radius: 52px;
  background: #F6F5F3;
  margin-right: 10px;
  max-width: 280px;
  margin-bottom: 10px;
}

.tags-text {
  text-align: center;
  padding: 8px 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}